<template>
  <div id="steps">
    <div id="steps-scroll" class="step-container">

      <v-layout v-bind:id="`step_${index}`"
        align-start justify-start class="steps"
        v-for="(step, index) in steps" :key="index"
        v-on:mouseover="mouseOver(index)"
        v-on:mouseout="mouseOut()"
        @click="clickStep(`#step_${index}`,step, true)">
        <v-flex class="flex-auto" :class="{'bounce': bounceIndex == index}" v-if="Object.hasOwnProperty.call(currentMarker, 'step')">

          <!-- current time -->
          <v-avatar :size="avatarSize" v-if="currentMarker.step.time == step.timestamp" color="primary accent-4">
            <v-icon v-if="isPlaying" color="white" class="rotating">cached</v-icon>
            <v-icon v-else color="white">play_arrow</v-icon>
          </v-avatar>

          <!-- past time -->
          <v-avatar :size="avatarSize" v-if="currentMarker.step.time > step.timestamp" class="past" :color="bounceIndex == index? 'secondary darken-2': 'white' ">
            <strong class="default past" color="primary">{{parseFloat(index)+1}}</strong>
            <v-icon class="play" color="white">replay</v-icon>
          </v-avatar>

          <!-- future time -->
          <v-avatar :size="avatarSize" v-if="currentMarker.step.time < step.timestamp" class="future" :color="bounceIndex == index? 'secondary': 'grey lighten-3' ">
            <span class="default">{{parseFloat(index)+1}}</span>
            <v-icon class="play" color="white">fast_forward</v-icon>
          </v-avatar>

        </v-flex>

        <v-flex v-if="Object.hasOwnProperty.call(currentMarker, 'step')">
          <!-- card is now -->
          <v-card v-if="currentMarker.step.time == step.timestamp" color="white" class=" current">
            <small class="time grey--text">{{step.timestamp | timeInHours}}</small>
              <h3 class="time grey--text text--darken-1">{{step.title || `Step ${parseFloat(index)+1}`}}</h3>
              <div class="time grey--text" v-html="step.description"></div>
          </v-card>
          <!-- card is past -->
          <v-card v-else-if="currentMarker.step.time > step.timestamp" class="elevation-0 primary--text text--darken-1">
            <small class="time ">{{step.timestamp | timeInHours}}</small>
              <h4 class="time  text--darken-1">{{step.title || `Step ${parseFloat(index)+1}`}}</h4>
              <div class="time " v-html="step.description"></div>
          </v-card>
          <!-- card is future -->
          <v-card v-else color="grey lighten-3" class="secondary--text text--darken-1 elevation-0">
            <small class="time grey--text">{{step.timestamp | timeInHours}}</small>
              <h4 :class="{'primary--text': currentMarker.step.time > step.timestamp}">{{step.title || `Step ${parseFloat(index)+1}`}}</h4>
              <div v-html="step.description"></div>
          </v-card>
        </v-flex>
      </v-layout>

    </div>
  </div>
</template>

<script>

export default {
  name: "steps",
  props: ['steps', 'currentMarker', 'isPlaying'],
  data(){
    return{
      avatarSize: 32,
      bounceIndex: 9999999999,
    }
  },
  watch:{
    currentMarker(newV, oldV){
      process.env.NODE_ENV === "development" ? console.log( 'newV', newV ) : null;
      if(this.$vuetify.breakpoint.mdAndUp){
        let el = `#step_${newV.index}`
        this.$scrollTo(el, '1s', {container:'#steps-scroll', easing: 'ease-out', offset: -15});
      }
    }
  },
  filters:{
    timeInHours: function(value) {
        let hours =  parseInt(Math.floor(value / 360));
        let minutes = parseInt(Math.floor((value - (hours * 360)) / 60));
        let seconds= parseInt((value - ((hours * 360) + (minutes * 60))) % 60);

        let dHours = (hours > 9 ? hours : '0' + hours);
        let dMins = (minutes > 9 ? minutes : '0' + minutes);
        let dSecs = (seconds > 9 ? seconds : '0' + seconds);

        return dHours + ":" + dMins + ":" + dSecs;
    }
  },
  methods: {
    clickStep(element, step, start){
      let selection = window.getSelection()
      // console.log('e', e);
      // console.log('selection', selection);
      let data = []
      data.element = element;
      data.step = step
      data.start = start != undefined ? true : false;
        this.$emit('onStepClicked', data)
    },
    isPast(time){
      if(time != undefined){
        return this.currentMarker > time
      }
      else{
        return false;
      }
    },
    mouseOver(index){
      this.bounceIndex = index
    },
    mouseOut(index){
      this.bounceIndex = 999999999;
    },

  },
  mounted(){
    process.env.NODE_ENV === "development" ? console.log( 'steps loaded', this.steps ) : null;
    process.env.NODE_ENV === "development" ? console.log( Object.hasOwnProperty.call(this.currentMarker, 'step') ) : null;
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#steps{
  padding-bottom: 90vh;
  .step-container{
    height: 86vh;
    overflow: scroll;
    padding-bottom: 70vh;
    padding-top: 1vh;
  }
  .steps{
    padding-right: 10px;
    &:hover{
      .v-avatar{
        cursor: pointer;
        .default{
          display: none;
        }
        .play{
          display: flex;
        }
      }
    }
    .layout{
      position: relative;
      &:after {
        position: absolute;
        content: '';
        left: 20px;
        top: 0;
        width: 1px;
        height: 100%;
        background-color: rgba(0,0,0,.1);
      }
      time{
        line-height: 20px;
      }
    }
    .v-card{
      padding: 0 10px 10px 10px;
      margin-bottom: 14px;
      opacity: .65;
      &.current{
        opacity: 1;
      }
      iframe, img{
        max-width: 100%;
      }
    }
    .v-avatar{
      margin: 0 8px 5px 5px;
      .play{
        display: none;
      }
      &.past{
        // opacity: .6;
      }
      &.future{
        opacity: .6;
      }
    }
    ul > li > p{
      margin: 0;
    }
  }

}
</style>
